<template>
  <!-- 设备引导 -->
  <el-dialog
    v-if="dialogVisible"
    title="设备使用引导"
    :visible.sync="dialogVisible"
    width="60%"
    :before-close="handleClose"
    :append-to-body="true"
  >
    <el-row :gutter="20">
      <el-col :span="16">
        <video src="@/assets/1.mp4" width="100%" controls="controls">
          您的浏览器不支持 video 标签。
        </video>
      </el-col>
      <el-col :span="8">
        <h3>操作引导：</h3>
        <p>1.使用爱色丽MA3测色仪进行测色，并将设备返回到任务列表</p>
        <p>2.确认需要同步数据的设备是否通过USB连接线连接到当前电脑</p>
        <p>3.选择需要同步数据的设备，点击“连接测试”按钮</p>
        <p>4.测试成功后点击“确认选择”按钮</p>
      </el-col>
      <el-col :span="24" style="margin-top: 20px">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="设备名称">
            <el-select
              v-model="formInline.id"
              placeholder="请选择设备"
              @change="handleChange"
              :disabled="isDisabled"
            >
              <el-option
                v-for="item in dataList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              size="small"
              type="primary"
              @click="checkTest"
              v-if="deviceStatus == '0'"
              :loading="loading"
              >连接测试</el-button
            >
            <el-button
              size="small"
              type="primary"
              @click="handleChange"
              v-if="deviceStatus == '2'"
              :loading="loading"
              >刷新设备</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="24" class="deviceStatus">
        <span>设备状态</span>
        <span v-if="deviceStatus === '0'"> 未连接 </span>
        <span v-if="deviceStatus === '1'" style="color: green"> 已连接 </span>
        <span v-if="deviceStatus === '2'" style="color: red">
          {{ deviceStatusStr }}，不可连接
        </span>
        <span v-if="deviceStatus === '99'" style="color: green">
          连接成功
        </span>
      </el-col>
      <!-- <el-col :span="24" style="margin-top:20px" v-if="deviceStatus==='2'">
        {{deviceStatusStr}}
      </el-col> -->
    </el-row>
    <span slot="footer" class="dialog-footer">
      <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
      <el-button
        v-if="deviceStatus == 99"
        class="btn"
        type="primary"
        @click="confirmSelectDevice"
        >确认选择</el-button
      >
      <el-button
        v-if="deviceStatus == 1"
        class="btn"
        type="primary"
        @click="breakConnectDevice"
        >断开连接</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getUserDeviceListApi } from '@/api/modules/deviceuser'
import {
  syncDeviceApi,
  checkDeviceStateApi,
  confirmSelectDeviceApi,
  breakConnectDeviceApi
} from '@/api/modules/device'
import Cookies from 'js-cookie'
export default {
  data() {
    return {
      websock: null,
      loading: false,
      isDisabled: false,
      deviceStatus: '0',
      deviceStatusStr: '',
      timer: null, //首先我在data函数里面进行定义定时器名称：
      timerNum: 0, // 设置定时器时间
      dataList: [],
      dataList_: [],
      selestData: [],
      formInline: {
        id: ''
      },
      dialogVisible: false
    }
  },
  beforeDestroy() {
    this.clearTimer()
  },
  methods: {
    init() {
      this.dialogVisible = true
      this.selectDeviceList()
    },
    checkTest() {
      this.loading = true
      this.isDisabled = true
      this.getSetmessage()
      //this.initWebSocket();
    },
    handleChange(index) {
      // console.log(index)
      this.selestData = this.dataList.filter(
        (item) => item.value === this.formInline.id
      )
      //判断设备状态
      this.checkDeviceState(this.formInline.id)

      // var data = this.dataList_.find( item =>{//假的，测试时用
      //   if (item.id == this.formInline.id ) {
      //     return item
      //   }
      // })
      // this.$store.commit('SET_DEVICEDATA', data)
      // Cookies.set('devicedata', data);
    },
    handleClose() {
      this.formInline.id = ''
      this.deviceStatus = '0'
      this.loading = false
      this.isDisabled = false
      this.dialogVisible = false
      if (this.websock) {
        this.websock.close()
      }
    },
    //获取设备
    getSetmessage() {
      if (!this.formInline.id) {
        this.loading = false
        this.isDisabled = false
        this.$message.error('请选择设备')
        return false
      }
      syncDeviceApi(this.formInline.id).then((response) => {
        this.initWebSocket()
        this.timer = setInterval(() => {
          //如果已经同步就关闭定时任务
          this.$message.warning('数据同步中，请确认设备是否有待同步数据')
          this.timerNum = this.timerNum + 1
          if (this.timerNum == 5) {
            this.clearTimer() // 关闭定时器
            this.loading = false
            this.isDisabled = false
          }
        }, 5000)
      })
    },
    clearTimer() {
      //清除定时器
      clearInterval(this.timer)
      this.timer = null
      this.timerNum = 0
    },
    async selectDeviceList() {
      const response = await getUserDeviceListApi()
      if (response && response.length > 0) {
        this.dataList_ = response
        this.dataList = response.map((item) => {
          return {
            value: item.id,
            label: item.deviceName
          }
        })
        if (
          this.$store.getters.userDevice &&
          this.$store.getters.userDevice.id
        ) {
          this.formInline.id = this.$store.getters.userDevice.id
          this.checkDeviceState(this.$store.getters.userDevice.id)
        } else {
          this.formInline.id = ''
          this.deviceStatus = '0'
        }
      }
    },
    async checkDeviceState(id) {
      if (!id) {
        this.loading = false
        this.$message.error('请选择设备')
        return false
      }
      this.formInline.id = id
      const data = await checkDeviceStateApi(id)
      if (data) {
        if (data.state === '0') {
          this.deviceStatus = '0'
          this.loading = false
          this.isDisabled = false
          //this.getSetmessage()
        } else if (data.state === 1) {
          this.deviceStatus = '1'
          this.loading = false
          this.isDisabled = true
        } else if (data.state === 2) {
          this.deviceStatus = '2'
          this.deviceStatusStr = data.msg
        }
      }
    },
    async confirmSelectDevice() {
      if (!this.formInline.id) {
        this.$message.error('请选择设备')
        return false
      }
      if (this.deviceStatus === '99') {
        await confirmSelectDeviceApi(this.formInline.id)

        const userDevice = this.$store.getters.userDevice
        userDevice.id = this.selestData[0].value
        userDevice.deviceName = this.selestData[0].label
        this.$store.dispatch('SetUserDevice', userDevice).then(() => {})
        this.loading = false
        this.isDisabled = false
        this.dialogVisible = false
      }
    },

    async breakConnectDevice() {
      if (!this.formInline.id) {
        this.$message.error('设备已断开')
        this.selectDeviceList()
        return false
      }
      await breakConnectDeviceApi(this.formInline.id)

      const userDevice = this.$store.getters.userDevice
      userDevice.id = ''
      userDevice.deviceName = ''
      this.$store.dispatch('SetUserDevice', userDevice).then(() => {
        // console.log(this.$store.getters.userDevice)
      })
      this.selectDeviceList()
      this.formInline.id = ''
      this.deviceStatus = '0'
      this.loading = false
      this.isDisabled = false
      if (this.websock) {
        this.websock.close()
      }
    },

    initWebSocket() {
      //初始化weosocket
      //ws地址
      var id = this.formInline.id
      var wsuri = process.env.VUE_APP_WS_API + '/websocket/search_product_' + id

      this.websock = new WebSocket(wsuri)
      this.websock.onmessage = this.websocketonmessage
      this.websock.onerror = this.websocketonerror
      this.websock.onopen = this.websocketonopen
      //this.websock.onclose = this.websocketclose
    },
    websocketonmessage(e) {
      //数据接收
      //code:0.搜索产品,1.状态变更,2.连接成功
      //msg:
      //data:
      var retData = JSON.parse(e.data)
      console.log(retData)
      var code = retData.code
      // console.log(code);
      var msg = retData.msg
      // console.log(msg);
      var data = retData.data
      // console.log(data);
      if (code == '0') {
        this.deviceStatus = '99'
        this.$message.success('连接成功')

        var data = this.dataList_.find((item) => {
          if (item.id == this.formInline.id) {
            return item
          }
        })
        this.$store.commit('SET_DEVICEDATA', data)
        Cookies.set('devicedata', data)
        this.clearTimer() // 关闭定时器
        this.loading = false
        this.isDisabled = false
        this.websock.close()
      }
    },
    websocketonopen() {
      // 连接建立之后执行send方法发送数据
      // let data = {
      //   code: 0,
      //   msg: '这是client：初次连接'
      // }
      // this.websocketsend(JSON.stringify(data))
    },
    websocketsend(agentData) {
      //数据发送
      this.websock.send(agentData)
    },
    websocketonerror(e) {
      console.log(e)
      return this.$message.error('WebSocket连接失败')
      //console.log( 'WebSocket连接失败')
    },
    websocketclose(e) {
      //关闭
      this.websock.close()
      // console.log("connection closed (" + e.code + ")");
    }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  margin: 0 !important;
}
p {
  color: #666666;
  font-size: 14px;
  line-height: 2;
}
.deviceStatus {
  span {
    &:nth-child(1) {
      font-size: 14px;
      font-weight: bold;
      color: #333;
      margin-right: 12px;
    }
    &:nth-child(2) {
      font-size: 14px;
      font-weight: bold;
      color: #999;
    }
  }
}
.el-dialog__footer {
  position: relative;
  padding: 0;
  .btn {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}
</style>
<style lang="scss">
.demo-form-inline {
  .el-form-item__label {
    width: auto !important;
  }
}
</style>
